@import url('https://fonts.googleapis.com/css2?family=Encode+Sans:wght@600&family=Hammersmith+One&family=Red+Hat+Display:ital,wght@0,300;0,400;0,500;0,600;0,700;1,300;1,400;1,500;1,600;1,700&display=swap');
@import url('http://fonts.cdnfonts.com/css/nineteenth');


/* Shared Styles */
*, *::before, *::after{

  margin:0;
  padding: 0;
  box-sizing: inherit;

}

html{

  scroll-behavior: smooth;

}

body{

  font-family: 'Red Hat Display', sans-serif;
  color: #0D4740;
  
  
}

.flexContainer{

  display: flex;
  flex-flow: row wrap;
  
}

.flexContainerCol{

  display: flex;
  flex-flow: column wrap;
  height: 100%;

}

section{
  
  padding: 30px 0;
  width: 100%;
  scroll-margin-top: 75px ;

}


.sectionHeading{

  font-size: 35px;
  margin: 0 0 30px 0;
  text-align: center;

}

.icon{

  font-size: 60px;

}

.repoIcon{

  font-size: 55px;
  margin-top: 2px;

}

.headingIcon{

  position: absolute;
  font-size: 38px;
  transform: translate(12px, 8.5px);

}

.fullWidth{

  width: 100%;
  border: 1px solid black;

}


/* Header Styles */
#header{
  padding: 1em;
  justify-content: space-between;
  align-items: center;
  background-color: #f16704;
  /* box-shadow: rgba(0, 0, 0, 0.12) 0px 1px 3px, rgba(0, 0, 0, 0.24) 0px 1px 2px; */
  box-shadow: rgba(0, 0, 0, 0.15) 0px 5px 15px 0px;
  z-index: 10;
}

@supports(position: sticky) or (position: -webkit-sticky){
  #header{
    position: -webkit-sticky; /* Safari */
    position: sticky;
    top: 0;
  }
}

#siteName, #siteName a{

  font-family: 'Hammersmith One', sans-serif;
  font-size: 42px;
  text-decoration: none;
  color: #FFFFFF;

}

#siteName{
  flex: 1 0 0;
}

#mainNavigation{

  width: 100%;
  flex: 2 0 0;

}

#headerMenu{

  list-style: none;
  width: 100%;
  justify-content: flex-end;

}

.headerOption + .headerOption {

  margin-left: 2.5em;

}

.headerLink{
  font-family: 'Red Hat Display', sans-serif;
  font-weight: 800;
  text-decoration: none;
  font-size: 22px;
  color: #FFFFFF;
}

.headerIcon{

  position: relative;
  /* font-size: 38px; */
  transform: translate(6px, 2px);

}

.headerLink, .headerLink:visited{

  color: #FFFFFF;

}

.headerLink:hover, .headerLink:active {

  color: #0D4740;

}


/* Profile Styles */
#profile{

  /* background-color: #D7E3DF; */
  background: rgb(230,246,240);
  background: radial-gradient(circle, rgba(230,246,240,1) 20%, rgba(204,223,217,1) 100%);
}

#profileContentBox{
  justify-content: space-evenly;
  flex-wrap: nowrap;
  width: 85%;
  margin: 0 auto;
}

#textBox, #imageBox {
  font-size: 1.25em;
  font-weight: 400;
  margin: 1em 2em;
}

#textBox{
  max-width: 650px;
  padding: 30px 1em;
}

#textHeading{

  padding-bottom: 30px;
  font-size: 40px;

}


#profileImage{
  align-self: center;
  border-radius: 25px;
  box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 1px 3px 1px;
  z-index: 1;
}

#profileColourSquare{

  max-width: 600px;
  height: 100%;
  width: 100%;
  position: relative;
  border-radius: 25px;
  transform: translate(-290px, 10px);
  background-color: #0D4740;
  box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 1px 3px 1px;

}

#imageContainer{

  max-width: 300px;

}

#vfxGroup{

  align-items: center;
  margin-top: 1em;
  
}


.vfxIcon{

  font-size: 50px;
  position: relative;
  top: .09em;
  margin-left: .15em;

}

#vfxText{

  font-style: italic;

}



/* My Work Styles */
#work {
  background-color: #FFFFFF;
}

#workExamples {

  align-items: center;
  justify-content: space-evenly;

}

.workExample{
  margin: 0 2em;
  padding: 2em;
  border-top: 2px solid #D7E3DF;

}

.workExample:nth-child(even) .workPanelFlex{

  flex-direction: row-reverse;

}

.workBox {

  max-width: 600px;
  min-width: 175px;
}

.imageBox {

  max-width: 600px;
  min-width: 175px;
  /* border: 1px solid black; */

}

.imageFlex{

  justify-content: center;

}

.workImage {

  border-radius: 25px;
  box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 1px 3px 1px;
  width: 100%;
  height: auto;

}

.imageGrow {

  transition: transform .4s;

}

.imageGrow:hover{

  -ms-transform: scale(1.075, 1.075);
  -webkit-transform: scale(1.075, 1.075);
  transform: scale(1.075, 1.075);

}

.workTitle {

  font-size: 26px;
  text-align: center;

}

.workDescription {

  margin: 0 auto;
  padding: 20px 0;
  width: 85%;
  font-size: 18px;
}

.workHeading {

  font-size: 21px;
  margin-bottom: 5px;
  
  /* text-decoration: underline;
  text-decoration-thickness: 3px; */

}

.workIcon {
  margin-top: 8px;
  font-size: 46px;

}

.workIcon + .workIcon {

  margin-left: 10px;

}

.workTextFlex{
  align-items: center;
  justify-content: center;

}

.workSkills{
  display: inline;
  flex: 3 0 0;
  text-align: center;
}

.workGit{
  margin-left: 4em;
  display: inline;
  flex: 1 0 0;
  text-align: center;
}


/* My Education Styles */

#education {
  background: rgb(230,246,240);
  background: radial-gradient(circle, rgba(230,246,240,1) 20%, rgba(204,223,217,1) 100%);
  flex: 1 0 0;
}

#educationList{

  margin: 0 auto;
  padding: 0 1em;
  width: fit-content;

}

.educationBox{
  border: 1px solid black;
}

.educationItem{

  list-style: none;

}

.educationItem + .educationItem{

  margin-top: 25px;

}

.schoolLogo{

  height: 42px;
  margin: .75em 0;

}

.degree{
  font-size: 20px;
  margin-bottom: 10px;
}

.schoolName, .timeFrame{
  font-size: 18px;
}

/* My Skills Styles */

#skills{
  background: rgb(230,246,240);
  background: radial-gradient(circle, rgba(230,246,240,1) 20%, rgba(204,223,217,1) 100%);
  flex: 1 0 0;
  border-right: 3px solid white;
}


.skillsFlex{
  flex-flow: column nowrap;
  align-items: center;
  justify-content: space-around;
  padding-top: .5em;
  height: 80%;
  vertical-align: top;
  
}

#skillsColumn{
  display: none;
}

.skill{
  font-size: 5em;
  width: 1.3em;
  text-align: center;
}

.skillRow{
  width: 100%;
}

.skillRowCenter{
  padding: 2.5em;
}


.skillRow .flexContainer{

  justify-content: space-evenly;

}

.SkillGroup{
  position: relative;
}

.toolTip{
  position: absolute;
  background-color:#0D4740;
  border-radius: 5px;
  font-size: 18px;
  font-weight: 700;
  color: #D7E3DF;
  top: -30px;
  left: 9px;
  text-align: center;
  width: 6em;
  z-index: 2;
}

.toolTipLong{
  width: 12em;
  left: -44px;
}

.toolTipHigh {
  top: -32px;
}

.triangleDown{
  position: absolute;
  width: 0; 
  height: 0; 
  top: -6px;
  left: 52px;
  border-left: 10px solid transparent;
  border-right: 10px solid transparent;
  border-top: 5px solid #0D4740;
  z-index: 1;
}

.triangleHigh {
  top: -8px;
}


.toolTipGroup{
  opacity: 0;
  transition: opacity .3s;
}

.SkillGroup:hover .toolTipGroup{
  opacity: 1;
}


/* Contact Me Styles */

#contact{

  margin: 0 auto;

}

#contactData {

  justify-content: space-evenly;
  /* border: 1px solid black; */
  
}

.contactLinkGroup{

  justify-content: space-evenly;
  width: 100%;
  margin: 30px 0 0 0;
}

#email {

  font-size: 22px;
  margin-bottom: 2em;
}

#email .flexContainer{

  align-items: center;

}

#emailIcon{
  font-size: 55px;
  margin-right: .5em;
  position: relative;
  top: 3px;
}

.contactLink .icon, .contactLink .icon:visited, .contactLink #emailIcon, .contactLink #emailIcon:visited, .contactLink .repoIcon, .contactLink .repoIcon:visited, .contactLink .vfxIcon, .contactLink .vfxIcon:visited{

  color: #0D4740;

}

.contactLink .icon:hover, .contactLink i:active,.contactLink #emailIcon:hover, .contactLink #emailIcon:active,  .contactLink .repoIcon:hover, .contactLink .repoIcon:active,.contactLink .vfxIcon:hover, .contactLink .vfxIcon:active{

  color: #f16704;
  transform: scale(1.1,1.1);

}

#imdbIcon{

  position: relative;
  font-size: 54px;
  top: 2px;

}

.linebreak{
  display: none;
}


/* Footer Styles */

#footer {

  padding: 1em;
  background-color: #f16704;
  color: #FFFFFF;
  font-weight: 400;
  font-size: 16px;
  box-shadow: rgba(0, 0, 0, 0.15) 0px 5px 15px 0px;
}

#email{

  font-weight: bold;

}


/* Media Queries */

@media screen and (max-width: 1345px){

  /* Making Project Examples Single Column */
  .workPanelFlex, .workExample:nth-child(even) .workPanelFlex{

    flex-direction: column;

  }

  .workDescription {
    width: 100%;
  }

  .workBox{

    /* border: 1px solid black; */
    padding: 2em 0 0 0;

  }

    /* Making Schooling Column */
    #schooling{

      flex-direction: column;
  
    }
  
    #skills{
      background-color: #D7E3DF;
      flex: 1 0 0;
      border-right: none;
    }

    #education{
      background: white;
    }

    #contact{
      background: rgb(230,246,240);
      background: radial-gradient(circle, rgba(230,246,240,1) 20%, rgba(204,223,217,1) 100%);
    }
  
    .skillRow + .skillRow{
      margin-top: 3em;
    }

    .skillRowCenter{
      padding: 0;
    }
    

}

@media screen and (max-width: 1070px){


  /* VFX Line */
  #vfxGroup{
    /* border: 1px solid black; */
    flex-direction: column;
    align-items: flex-start;   
  }
  
  
  .vfxIcon{
    top: 0;
    right: .1em;
    margin-left: 0;
  }
  
  #vfxText{
    font-style: italic;
  }
  
}


@media screen and (max-width: 995px){

  /* Putting header menu on new row */
  #header {
    flex-direction: column;
  } 

  #header h2 {
    align-self: flex-start;
  } 

  #headerMenu{
    justify-content: space-between;
  }

  .headerOption + .headerOption {
    margin-left: 0;
  }

  nav{
    margin-top: 1em;
  }

  section{

    scroll-margin-top: 115px ;
  
  }

  /* Reducing Profile Section Margins */
  #profileContentBox{
    width: 100%;
  }


  /* VFX Link Back to Row */
  #vfxGroup{
    flex-direction: row;
    align-items: center;  
  }
  
  
  .vfxIcon{
  
    font-size: 50px;
    position: relative;
    right: 0;
    top: .09em;
    margin-left: .15em;
  
  }
    

  /* Moving Profile Picture under text */

  #profileContentBox {
    flex-flow: column wrap;
  }

  #textBox{
    align-self: center;
  }

  #profile{
    padding-top: 0;
  }

  #imageBox {
    margin-top:0;
  }



  /* Adds linebreak to Contact Flex */
  .linebreak{
    display: block;
    width: 100%;
    height: 0;
  }

  .fullWidth + .fullWidth {
    margin-top: 2em;
  }

}


@media screen and (max-width: 650px){

  /* Reducing Profile Margins */



  /* Shrinking Skill Tool Tips */

  .skill{
    font-size: 65px;
  }

  .toolTip{
    font-size: 15px;
    top: -26px;
    left: -3px;
  }
  
  .toolTipLong{
    width: 12em;
    left: -44px;
  }
  
  .toolTipHigh {
    top: -26px;
  }
  
  .triangleDown{
    top: -7px;
    left: 33px;
  }
  
  .triangleHigh {
    top: -7px;
  }

}


/* Strinking Text */
@media screen and (max-width: 520px){

  #vfxGroup{
    flex-direction: column;
    align-items: flex-start;   
  }
  
  
  .vfxIcon{
    top: 0;
    right: .1em;
    margin-left: 0;
  }
  
}


/* Strinking Text */
@media screen and (max-width: 450px){

  #textHeading{

    padding-bottom: 30px;
    font-size: 32px;
  
  }

  .sectionHeading{

    font-size: 25px;
    margin: 0 0 30px 0;
  
  }

  .headingIcon{
    font-size: 25px;
  }

  .textContent{

    font-size: .75em;

  }

  .headerLink{
    font-size: 16px;
  }

  .workIconFlex{

    flex-direction: column;

  }

  .vfxIcon{
    font-size: .85em;
  }
  
  #vfxText{
    font-size: .75em;
  }

  .workGit{
    margin-left: 0;
    margin-top: 1em;
  }

  /* Shrinking Skill Tool Tips */

  .skill{
    font-size: 45px;
  }

  .toolTip{
    font-size: 12px;
    top: -23px;
    left: -1px;
  }
  
  .toolTipLong{
    width: 12em;
    left: -28px;
  }
  
  .toolTipHigh {
    top: -23px;
  }
  
  .triangleDown{
    top: -7px;
    left: 25px;
  }
  
  .triangleHigh {
    top: -7px;
  }

  #email {

    font-size: 18px;
    margin-bottom: 2em;
  }
  
  #emailIcon{
    font-size: 45px;
    top: 0;
  }

  .icon{
    font-size: 53px;
  }

  #imdbIcon{
    position: relative;
    font-size: 48px;
    top: 2px;
  }

}


@media screen and (max-width: 380px){
  /* Making Profile Picture Strink when the screen gets around mobile size */
  #profileImage{

    width: 100%;
    height: auto;
  
  }


}

@media screen and (max-width: 350px){
  /* Making Profile Picture Strink when the screen gets around mobile size */

  .headerLink{
    font-size: 15px;
  }

  /* Shrinking Skill Tool Tips */
  .skill{
    font-size: 40px;
  }

  .toolTip{
    font-size: 10px;
    top: -20px;
    left: -5px;
  }
  
  .toolTipLong{
    width: 12em;
    left: -36px;
  }
  
  .toolTipHigh {
    top: -20px;
  }
  
  .triangleDown{
    top: -7px;
    left: 16px;
  }
  
  .triangleHigh {
    top: -7px;
  }

}



